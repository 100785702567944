// extracted by mini-css-extract-plugin
export var background = "ContactForm-module--background--rnu3I";
export var dropDown = "ContactForm-module--dropDown--7j0vP";
export var dropDownChevron = "ContactForm-module--dropDownChevron--tz1Eg";
export var form = "ContactForm-module--form--gNR+-";
export var formHolder = "ContactForm-module--formHolder--evSPg";
export var headingForm = "ContactForm-module--headingForm--34cIr";
export var icon = "ContactForm-module--icon--BL7-W";
export var inputField = "ContactForm-module--inputField--KLOVz";
export var inputLabel = "ContactForm-module--inputLabel--kIXLI";
export var submitButton = "ContactForm-module--submitButton--HPx3Z";
export var submitText = "ContactForm-module--submitText--zKUf4";
export var textArea = "ContactForm-module--textArea--BnnSW";