import Layout from '../../components/Navigation/layout'
import * as React from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import {useEffect} from "react";
import {Col, Container, Row} from "react-bootstrap";
import * as style from "../../components/ContactForm/ContactForm.module.scss";

const ContactPage = () => {

    return (
        <Layout pageTitle="Kontakt">
            <ContactForm />
        </Layout>
    )
}

export default ContactPage;
