import * as React from "react";
import {useState} from "react";
// @ts-ignore
import * as style from './ContactForm.module.scss';
import {Button, Col, Form, Row} from "react-bootstrap";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
// @ts-ignore
import * as mainStyle from '../../theme/main.module.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/pro-light-svg-icons";
import {StaticImage} from "gatsby-plugin-image";
import {Client, handleApiError} from "../../services/ApiService";
import {toast} from "react-toastify";

interface FormInputs {
    Name: string,
    Phone: string,
    Email: string,
    Message: string,
}

const schema = yup.object().shape({
    Name: yup.string().required('Bitte gib einen Namen an.'),
    Phone: yup.string().required('Bitte gib eine Telefonnummer an.'),
    Email: yup.string().required('Bitte gib eine E-Mail Adresse an.').email('Die angegebene E-Mail Adresse ist ungültig'),
    Message: yup.string().optional(),
})

const ContactForm = () => {
    const [sendForm, setSendForm] = useState(false);
    const {register, handleSubmit, formState: {errors}} = useForm<FormInputs>({
        resolver: yupResolver(schema)
    });

    function submitContactForm(data) {
        Client.Raw.post('Inquiries/sendContactMail', {
            Name: data.Name,
            Phone: data.Phone,
            Email: data.Email,
            Message: data.Message ? data.Message : ''
        }).then(() => {
            toast.success('Nachricht gesendet!');
            setSendForm(true);
        }).catch(handleApiError);
    }

    return (
        <div className={style.formHolder}>

            <div className={style.form}>
                {sendForm ?
                    <div className={style.submitText}>
                        <h2> Vielen Dank für deine Nachricht! </h2> <br/>
                        <span><b>Wir werden uns so schnell wie möglich bei dir melden!</b></span>
                    </div>
                    : <Form onSubmit={handleSubmit(submitContactForm)}>
                        <h2 className={style.headingForm}> Du hast Fragen? Wir hören gerne von dir!</h2>
                        <Row>
                            <Col lg={6} md={6}>
                                <Form.Group controlId="formFirstName" className="mb-2">
                                    <Form.Label className={style.inputLabel}>Name*</Form.Label>
                                    <Form.Control type="text" className={style.inputField} {...register('Name')}/>
                                    <Form.Text className={mainStyle.errorMsg}>
                                        {errors.Name?.message}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6}>
                                <Form.Group controlId="formLastName">
                                    <Form.Label className={style.inputLabel}>Telefonnummer*</Form.Label>
                                    <Form.Control type="text" className={style.inputField} {...register('Phone')} />
                                    <Form.Text className={mainStyle.errorMsg}>
                                        {errors.Phone?.message}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col lg={12} md={12}>
                                <Form.Group controlId="formEmail">
                                    <Form.Label className={style.inputLabel}>E-Mail*</Form.Label>
                                    <Form.Control type="email" className={style.inputField} {...register('Email')}/>
                                    <Form.Text className={mainStyle.errorMsg}>
                                        {errors.Email?.message}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col lg={12} md={12}>
                                <Form.Group controlId="formShortInfo" className="mb-2">
                                    <Form.Label className={style.inputLabel}>Dein Anliegen</Form.Label>
                                    <Form.Control as="textarea" rows={5} resize
                                                  className={style.textArea}  {...register('Message')}/>
                                    <Form.Text className={mainStyle.errorMsg}>
                                        {errors.Message?.message}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col lg={12} md={12} className="d-flex justify-content-center">
                                <Button type={"submit"} className={style.submitButton}>
                                    <FontAwesomeIcon icon={faCheck} className={style.icon}/>
                                    Formular Absenden
                                </Button>
                            </Col>
                        </Row>
                    </Form>}
            </div>
        </div>
    )
}

export default ContactForm;
